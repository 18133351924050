import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IPrompt, IUpdatePromptConfigurationParams } from "@/models/prompt";

const initialState = {
  promptList: null as IPrompt[] | null,
  selectedPrompt: null as IPrompt | null,
  promptConfiguration: null as IUpdatePromptConfigurationParams | null,
};

const promptSlices = createSlice({
  name: "prompt",
  initialState,
  reducers: {
    setPromptList: (state, action: PayloadAction<IPrompt[]>) => {
      state.promptList = action.payload;
    },
    setSelectedPrompt: (state, action: PayloadAction<IPrompt>) => {
      state.selectedPrompt = action.payload;
    },
    setPromptConfiguration: (
      state,
      action: PayloadAction<IUpdatePromptConfigurationParams | null>
    ) => {
      state.promptConfiguration = action.payload;
    },
  },
});

export const { setSelectedPrompt, setPromptList, setPromptConfiguration } =
  promptSlices.actions;

export default promptSlices.reducer;
